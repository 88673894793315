// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-camps-js": () => import("./../../../src/pages/camps.js" /* webpackChunkName: "component---src-pages-camps-js" */),
  "component---src-pages-clinics-js": () => import("./../../../src/pages/clinics.js" /* webpackChunkName: "component---src-pages-clinics-js" */),
  "component---src-pages-coaches-js": () => import("./../../../src/pages/coaches.js" /* webpackChunkName: "component---src-pages-coaches-js" */),
  "component---src-pages-covid-19-js": () => import("./../../../src/pages/covid19.js" /* webpackChunkName: "component---src-pages-covid-19-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-forms-js": () => import("./../../../src/pages/forms.js" /* webpackChunkName: "component---src-pages-forms-js" */),
  "component---src-pages-gallery-js": () => import("./../../../src/pages/gallery.js" /* webpackChunkName: "component---src-pages-gallery-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-lessons-js": () => import("./../../../src/pages/lessons.js" /* webpackChunkName: "component---src-pages-lessons-js" */),
  "component---src-pages-personal-training-js": () => import("./../../../src/pages/personal_training.js" /* webpackChunkName: "component---src-pages-personal-training-js" */),
  "component---src-pages-schedule-js": () => import("./../../../src/pages/schedule.js" /* webpackChunkName: "component---src-pages-schedule-js" */),
  "component---src-pages-tournaments-js": () => import("./../../../src/pages/tournaments.js" /* webpackChunkName: "component---src-pages-tournaments-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */)
}

